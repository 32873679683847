import { ChangeEvent, HTMLInputTypeAttribute, ImgHTMLAttributes, KeyboardEvent, useState } from 'react'
import { Controller, Control, ControllerRenderProps, FieldErrors } from 'react-hook-form'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@mui/material'
// @ts-ignore
import { MuiChipsInput } from 'mui-chips-input'
// @ts-ignore
import { MuiColorInput } from 'mui-color-input'
import _ from 'lodash'
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material'
import styled, { AnyStyledComponent } from 'styled-components'

const SELECT_ALL = 'SELECT_ALL'

export interface FormProps {
  control?: Control<any, any>
  errors?: FieldErrors<any>
  formInputsData?: FormInputData[]
  loading?: boolean
}

export interface FormInputData {
  type:
    | 'emails'
    | 'select'
    | 'checkbox'
    | 'text'
    | 'color'
    | 'file'
    | 'multiple-autocomplete'
    | 'multiple-autocomplete-checkbox'
    | 'switch'
  name: string
  label: string
  disabled?: boolean
  items?: Item[]
  inputType?: HTMLInputTypeAttribute
  imgSrc?: ImgHTMLAttributes<HTMLImageElement>['src']
  multiline?: boolean
  onlyIntegers?: boolean
  labelShrink?: boolean
}

export interface Item {
  value: string
  label: string
  groupLabel?: string
}

const CheckboxStyled = styled(Checkbox as AnyStyledComponent)`
  margin-right: 0.5rem;
`

const Image = styled.img`
  height: 100%;
  width: auto;
  object-fit: contain;
`

const Form = ({ control, errors, formInputsData, loading }: FormProps) => {
  const [emailsInputValues, setEmailsInputValues] = useState<{ [name: string]: string }>({})

  const getFormError = (name: string) => {
    const formError = _.get(errors, name)
    if (!formError) {
      return
    }

    if (Array.isArray(formError)) {
      return formError.map((error) => String(error.message)).join('\n')
    }

    return String(formError.message)
  }

  const allowOnlyIntegers = <T,>(e: KeyboardEvent<T>) => {
    if (e.key === 'Backspace' || !isNaN(Number(e.key))) {
      return
    }

    e.preventDefault()
  }

  const handleEmailsInputChange = (name: ControllerRenderProps['name'], value: string) => {
    setEmailsInputValues((prevEmailsInputValues) => ({ ...prevEmailsInputValues, [name]: value }))
  }

  const handleEmailsPaste = async (
    name: ControllerRenderProps['name'],
    prevValues: ControllerRenderProps['value'],
    onChange: ControllerRenderProps['onChange'],
  ) => {
    try {
      const text = (await window.navigator.clipboard.readText()).trim()

      const values = text
        .replace(/\r/g, '')
        .split('\n')
        .filter((value) => !!value)

      onChange([...prevValues, ...values])

      setEmailsInputValues((prevEmailsInputValues) => ({ ...prevEmailsInputValues, [name]: '' }))
    } catch (error) {
      console.error(error)
    }
  }

  const handleCheckboxChange = (
    itemValue: string,
    prevValues: ControllerRenderProps['value'],
    onChange: ControllerRenderProps['onChange'],
  ) => {
    const values: string[] = prevValues

    if (values.some((value) => value === itemValue)) {
      return onChange(values.filter((value) => value !== itemValue))
    }

    return onChange([...values, itemValue])
  }

  const renderInput = (formInputData: FormInputData) => {
    const error = getFormError(formInputData.name)

    switch (formInputData.type) {
      case 'emails':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => (
              <MuiChipsInput
                {...field}
                disabled={formInputData.disabled || loading}
                fullWidth
                error={!!error}
                helperText={error}
                label={formInputData.label}
                inputValue={emailsInputValues[field.name] ?? ''}
                onInputChange={(value: string) => handleEmailsInputChange(field.name, value)}
                onPaste={() => handleEmailsPaste(field.name, field.value, field.onChange)}
              />
            )}
            defaultValue={[]}
          />
        )
      case 'checkbox':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => {
              const selectedValuesObj =
                (field.value as string[]).reduce<{ [value: string]: string }>((obj, value) => {
                  obj[value] = value
                  return obj
                }, {}) ?? {}

              return (
                <FormControl error={!!error}>
                  <FormLabel>{formInputData.label}</FormLabel>

                  <FormGroup>
                    {formInputData.items?.map((item, index) => (
                      <FormControlLabel
                        key={index}
                        label={item.label}
                        disabled={formInputData.disabled || loading}
                        control={
                          <Checkbox
                            {...field}
                            value={item.value}
                            checked={!!selectedValuesObj[item.value]}
                            onChange={() => handleCheckboxChange(item.value, field.value, field.onChange)}
                          />
                        }
                      />
                    ))}
                  </FormGroup>

                  {!!error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
              )
            }}
            defaultValue={[]}
          />
        )
      case 'select':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth error={!!error}>
                <InputLabel>{formInputData.label}</InputLabel>

                <Select {...field} label={formInputData.label} disabled={formInputData.disabled || loading}>
                  {formInputData.items?.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>

                {!!error && <FormHelperText>{error}</FormHelperText>}
              </FormControl>
            )}
            defaultValue=""
          />
        )
      case 'text':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type={formInputData.inputType ?? 'text'}
                label={formInputData.label}
                error={!!error}
                helperText={error}
                fullWidth
                disabled={formInputData.disabled || loading}
                multiline={formInputData.multiline}
                sx={formInputData.multiline ? { overflowWrap: 'break-word' } : {}}
                InputLabelProps={{ shrink: formInputData.labelShrink }}
                onKeyDown={formInputData.onlyIntegers ? allowOnlyIntegers : undefined}
                onChange={(e) => {
                  let value = e.target.value
                  if (formInputData.inputType === 'number' && !formInputData.onlyIntegers) {
                    if (/^\d*\.?\d{0,2}$/.test(value) || value === '') {
                      field.onChange(value)
                    } else {
                      e.preventDefault()
                    }
                    return
                  }
                  field.onChange(e)
                }}
              />
            )}
            defaultValue=""
          />
        )
      case 'color':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => (
              <MuiColorInput
                {...field}
                label={formInputData.label}
                error={!!error}
                helperText={error}
                fullWidth
                disabled={formInputData.disabled || loading}
                format="hex"
              />
            )}
            defaultValue=""
          />
        )
      case 'file':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field: { value, ...field } }) => (
              <Box>
                <Box pb={1}>
                  <InputLabel>{formInputData.label}</InputLabel>
                </Box>

                <Box
                  display="grid"
                  columnGap={2}
                  gridTemplateColumns={formInputData.imgSrc ? '0.7fr 0.3fr' : '1fr'}
                  alignItems="center"
                >
                  <TextField
                    {...field}
                    type="file"
                    fullWidth
                    error={!!error}
                    helperText={error}
                    disabled={formInputData.disabled || loading}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => field.onChange(e.target.files?.[0])}
                  />

                  {!!formInputData.imgSrc && (
                    <Box display="flex" justifyContent="center" height="3.5rem">
                      <Image src={formInputData.imgSrc} alt="wallet-img" />
                    </Box>
                  )}
                </Box>
              </Box>
            )}
            defaultValue={null}
          />
        )
      case 'multiple-autocomplete':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => {
              const itemsObj =
                formInputData.items?.reduce<{ [value: string]: Item }>((obj, item) => {
                  obj[item.value] = item
                  return obj
                }, {}) ?? {}

              const selectedOptions = (field.value as string[]).map((v) => itemsObj[v])

              return (
                <Autocomplete<Item, true>
                  {...field}
                  value={selectedOptions}
                  multiple
                  options={formInputData.items ?? []}
                  getOptionLabel={(option) => option?.label}
                  filterSelectedOptions
                  disabled={formInputData.disabled || loading}
                  renderInput={(params) => (
                    <TextField {...params} error={!!error} helperText={error} label={formInputData.label} />
                  )}
                  onChange={(_, items) => field.onChange(items.map((item) => item.value))}
                />
              )
            }}
            defaultValue={[]}
          />
        )
      case 'multiple-autocomplete-checkbox':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => {
              const itemsObj =
                formInputData.items?.reduce<{ [value: string]: Item }>((obj, item) => {
                  obj[item.value] = item
                  return obj
                }, {}) ?? {}

              const selectedOptions = (field.value as string[]).map((v) => itemsObj[v])

              const options = formInputData.items?.length
                ? [{ value: SELECT_ALL, label: 'Select All' }, ...formInputData.items]
                : []

              const isAllSelected = formInputData.items?.length === selectedOptions.length

              const handleSelectAll = () => {
                if (isAllSelected) {
                  field.onChange([])
                  return
                }

                field.onChange(formInputData.items?.map((item) => item.value))
              }

              return (
                <Autocomplete<Item, true>
                  {...field}
                  value={selectedOptions}
                  multiple
                  disablePortal
                  options={options}
                  getOptionLabel={(option) => option?.label}
                  groupBy={(option) => option.groupLabel ?? ''}
                  disableCloseOnSelect
                  disabled={formInputData.disabled || loading}
                  renderInput={(params) => (
                    <TextField {...params} error={!!error} helperText={error} label={formInputData.label} />
                  )}
                  renderOption={(props, option, { selected }) =>
                    option.value === SELECT_ALL ? (
                      <li {...props} onClick={handleSelectAll}>
                        <CheckboxStyled
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          checked={isAllSelected}
                        />

                        {option.label}
                      </li>
                    ) : (
                      <li {...props}>
                        <CheckboxStyled
                          icon={<CheckBoxOutlineBlank fontSize="small" />}
                          checkedIcon={<CheckBox fontSize="small" />}
                          checked={selected}
                        />

                        {option.label}
                      </li>
                    )
                  }
                  onChange={(_, items) => field.onChange(items.map((item) => item.value))}
                />
              )
            }}
            defaultValue={[]}
          />
        )
      case 'switch':
        return (
          <Controller
            name={formInputData.name}
            control={control}
            render={({ field }) => (
              <FormControl error={!!error}>
                <FormGroup>
                  <FormControlLabel
                    label={formInputData.label}
                    labelPlacement="start"
                    disabled={formInputData.disabled || loading}
                    control={<Switch {...field} checked={field.value} />}
                  />
                </FormGroup>
              </FormControl>
            )}
            defaultValue={false}
          />
        )
      default:
        return null
    }
  }

  const renderInputs = () => {
    return formInputsData?.map((formInputData, index) => (
      <Grid key={index} item xs>
        {renderInput(formInputData)}
      </Grid>
    ))
  }

  return (
    <Grid container direction="column" rowSpacing={2} sx={{ whiteSpace: 'pre-wrap' }}>
      {renderInputs()}
    </Grid>
  )
}

export default Form
