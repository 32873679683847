import { useSelector } from 'react-redux'
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Form, { FormInputData, Item } from '@/components/Form'
import { NestedKeyOf } from '@/types'
import {
  CreateAnonymousLoyaltyCardBulkContract,
  CreateLoyaltyDetailsContract,
  ValidationErrorContract,
} from '@/types/api'
import { RootState } from '@/utilities/store'
import { DATE_FORMAT, dayjs } from '@/utilities/dayjs'
import { setFormErrors } from '@/utilities/functions'
import { selectIsUserRole } from '@/models/authentication'

const INITIAL_ANONYMOUS_CARD_FORM_VALUES: AnonymousCardFormValues = {
  count: '',
  customizationId: '',
  discount: {
    maxAmount: '',
    orderCount: '',
    dailyOrderCount: '',
    totalAmount: '',
    totalMonthlyAmount: '',
    validTo: '',
  },
  restaurantIds: [],
  companyName: '',
  companyCode: '',
  description: '',
}

const anonymousCardSchema: yup.ObjectSchema<AnonymousCardFormValues> = yup
  .object({
    count: yup.string().label('Count').required(),
    customizationId: yup.string().required().label('Customization'),
    discount: yup
      .object({
        dailyOrderCount: yup.string().label('Daily order count'),
        maxAmount: yup.string().label('Max amount'),
        orderCount: yup.string().label('Order count'),
        totalAmount: yup.string().label('Total amount'),
        totalMonthlyAmount: yup.string().label('Total monthly amount'),
        validTo: yup.string().label('Valid to'),
      })
      .required(),
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
    companyName: yup.string().label('Company name'),
    companyCode: yup.string().label('Company code'),
    description: yup.string().label('Description'),
  })
  .required()

export interface AnonymousCardFormRef {
  createCard: () => void
}

interface AnonymousCardFormProps {
  loading?: boolean
  customizationItems?: Item[]
  onCreate?: (
    data: CreateAnonymousLoyaltyCardBulkContract,
  ) => Promise<{ errors: ValidationErrorContract[] } | undefined>
}

interface AnonymousCardFormValues extends Omit<CreateAnonymousLoyaltyCardBulkContract, 'discount' | 'count'> {
  count: string
  discount: {
    [Property in keyof CreateLoyaltyDetailsContract]: string
  }
  restaurantIds?: string[]
  companyName?: string
  companyCode?: string
  description?: string
}

interface AnonymousCardFormData extends FormInputData {
  name: NestedKeyOf<CreateAnonymousLoyaltyCardBulkContract>
}

const AnonymousCardForm = forwardRef<AnonymousCardFormRef, AnonymousCardFormProps>(
  ({ loading, customizationItems, onCreate }, ref) => {
    const user = useSelector((state: RootState) => state.authentication.user)

    const isUserRole = useSelector(selectIsUserRole)

    const {
      control,
      formState: { errors },
      handleSubmit,
      reset,
      setError,
    } = useForm<AnonymousCardFormValues>({
      resolver: yupResolver(anonymousCardSchema),
    })

    const anonymousCardFormData = useMemo(() => {
      const formData: AnonymousCardFormData[] = [
        {
          type: 'text',
          name: 'count',
          label: 'Count',
          inputType: 'number',
        },
        {
          type: 'multiple-autocomplete-checkbox',
          name: 'restaurantIds',
          label: 'Restaurants',
          items: user?.restaurants?.map((restaurant) => ({ value: restaurant.id!, label: restaurant.title ?? '' })),
        },
        {
          type: 'select',
          name: 'customizationId',
          label: 'Customization',
          items: customizationItems,
        },
        {
          type: 'text',
          name: 'description',
          label: 'Description',
        },
        {
          type: 'text',
          name: 'companyName',
          label: 'Company name',
        },
        {
          type: 'text',
          name: 'companyCode',
          label: 'Company code',
        },
        {
          type: 'text',
          name: 'discount.maxAmount',
          label: 'Max amount',
          inputType: 'number',
        },
        {
          type: 'text',
          name: 'discount.orderCount',
          label: 'Order count',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.dailyOrderCount',
          label: 'Daily order count',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.totalAmount',
          label: 'Total amount',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.totalMonthlyAmount',
          label: 'Total monthly amount',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.validTo',
          label: 'Valid to',
          inputType: 'datetime-local',
        },
      ]

      const filteredFormData = formData.filter(({ name }) => {
        if (!isUserRole) {
          return true
        }

        if (name === 'companyName' || name === 'companyCode') {
          return false
        }

        return true
      })

      return filteredFormData
    }, [customizationItems, isUserRole, user?.restaurants])

    const onSubmit: SubmitHandler<AnonymousCardFormValues> = async (createFormData) => {
      const mappedCreateFormData = Object.entries(createFormData).reduce<{ [key: string]: any }>(
        (mainObj, [mainKey, mainValue]) => {
          if (!mainValue) {
            mainObj[mainKey] = null
            return mainObj
          }

          if (mainKey === 'count') {
            mainObj[mainKey] = Number(mainValue)
            return mainObj
          }

          if (mainKey === 'discount') {
            mainObj[mainKey] = Object.entries<any>(mainValue).reduce<{ [key: string]: any }>(
              (discountObj, [discountKey, discountValue]) => {
                if (!discountValue) {
                  discountObj[discountKey] = null
                  return discountObj
                }

                if (discountKey === 'validTo') {
                  discountObj[discountKey] = dayjs.utc(discountValue).unix()
                  return discountObj
                }

                discountObj[discountKey] = Number(discountValue)
                return discountObj
              },
              {},
            )
            return mainObj
          }

          mainObj[mainKey] = mainValue
          return mainObj
        },
        {},
      ) as CreateAnonymousLoyaltyCardBulkContract

      const res = await onCreate?.(mappedCreateFormData)

      setFormErrors(res?.errors, setError)
    }

    const createCard = () => {
      handleSubmit(onSubmit)()
    }

    useEffect(() => {
      reset({
        ...INITIAL_ANONYMOUS_CARD_FORM_VALUES,
        count: '1',
        discount: {
          ...INITIAL_ANONYMOUS_CARD_FORM_VALUES.discount,
          validTo: dayjs().endOf('day').add(1, 'month').format(DATE_FORMAT),
        },
        customizationId: user?.customizationId ?? '',
        restaurantIds: isUserRole ? user?.restaurants?.map((restaurant) => restaurant.id) ?? [] : [],
      })
    }, [isUserRole, reset, user?.customizationId, user?.restaurants])

    useImperativeHandle(
      ref,
      () => {
        return { createCard }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    return <Form control={control} errors={errors} formInputsData={anonymousCardFormData} loading={loading} />
  },
)

export default AnonymousCardForm
