import { useSelector } from 'react-redux'
import { forwardRef, useEffect, useImperativeHandle, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Form, { FormInputData, Item } from '@/components/Form'
import { NestedKeyOf } from '@/types'
import { CreateLoyaltyCardBulkContract, CreateLoyaltyDetailsContract, ValidationErrorContract } from '@/types/api'
import { RootState } from '@/utilities/store'
import { DATE_FORMAT, dayjs } from '@/utilities/dayjs'
import { setFormErrors } from '@/utilities/functions'
import { selectIsUserRole } from '@/models/authentication'

const INITIAL_PERSONAL_CARD_FORM_VALUES: PersonalCardFormValues = {
  emails: [],
  customizationId: '',
  discount: {
    maxAmount: '',
    orderCount: '',
    dailyOrderCount: '',
    totalAmount: '',
    totalMonthlyAmount: '',
    validTo: '',
  },
  restaurantIds: [],
  companyName: '',
  companyCode: '',
  description: '',
}

const personalCardSchema: yup.ObjectSchema<PersonalCardFormValues> = yup
  .object({
    emails: yup
      .array()
      .of(
        yup
          .string()
          .email(({ originalValue }) => `${originalValue} must be a valid email`)
          .required(),
      )
      .min(1)
      .required()
      .label('Emails'),
    customizationId: yup.string().required().label('Customization'),
    discount: yup
      .object({
        dailyOrderCount: yup.string().label('Daily order count'),
        maxAmount: yup.string().label('Max amount'),
        orderCount: yup.string().label('Order count'),
        totalAmount: yup.string().label('Total amount'),
        totalMonthlyAmount: yup.string().label('Total monthly amount'),
        validTo: yup.string().label('Valid to'),
      })
      .required(),
    restaurantIds: yup.array().of(yup.string().required()).label('Restaurants'),
    companyName: yup.string().label('Company name'),
    companyCode: yup.string().label('Company code'),
    description: yup.string().label('Description'),
  })
  .required()

export interface PersonalCardFormRef {
  createCard: () => void
}

interface PersonalCardFormProps {
  loading?: boolean
  customizationItems?: Item[]
  onCreate?: (data: CreateLoyaltyCardBulkContract) => Promise<{ errors: ValidationErrorContract[] } | undefined>
}

interface PersonalCardFormValues extends Omit<CreateLoyaltyCardBulkContract, 'discount'> {
  discount: {
    [Property in keyof CreateLoyaltyDetailsContract]: string
  }
  restaurantIds?: string[]
  companyName?: string
  companyCode?: string
  description?: string
}

interface PersonalCardFormData extends FormInputData {
  name: NestedKeyOf<CreateLoyaltyCardBulkContract>
}

const PersonalCardForm = forwardRef<PersonalCardFormRef, PersonalCardFormProps>(
  ({ loading, customizationItems, onCreate }, ref) => {
    const user = useSelector((state: RootState) => state.authentication.user)

    const isUserRole = useSelector(selectIsUserRole)

    const {
      control,
      formState: { errors },
      handleSubmit,
      reset,
      setError,
    } = useForm<PersonalCardFormValues>({
      resolver: yupResolver(personalCardSchema),
    })

    const personalCardFormData = useMemo(() => {
      const formData: PersonalCardFormData[] = [
        {
          type: 'emails',
          name: 'emails',
          label: 'Emails',
        },
        {
          type: 'multiple-autocomplete-checkbox',
          name: 'restaurantIds',
          label: 'Restaurants',
          items: user?.restaurants?.map((restaurant) => ({ value: restaurant.id!, label: restaurant.title ?? '' })),
        },
        {
          type: 'select',
          name: 'customizationId',
          label: 'Customization',
          items: customizationItems,
        },
        {
          type: 'text',
          name: 'description',
          label: 'Description',
        },
        {
          type: 'text',
          name: 'companyName',
          label: 'Company name',
        },
        {
          type: 'text',
          name: 'companyCode',
          label: 'Company code',
        },
        {
          type: 'text',
          name: 'discount.maxAmount',
          label: 'Max amount',
          inputType: 'number',
        },
        {
          type: 'text',
          name: 'discount.orderCount',
          label: 'Order count',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.dailyOrderCount',
          label: 'Daily order count',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.totalAmount',
          label: 'Total amount',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.totalMonthlyAmount',
          label: 'Total monthly amount',
          inputType: 'number',
          onlyIntegers: true,
        },
        {
          type: 'text',
          name: 'discount.validTo',
          label: 'Valid to',
          inputType: 'datetime-local',
        },
      ]

      const filteredFormData = formData.filter(({ name }) => {
        if (!isUserRole) {
          return true
        }

        if (name === 'companyName' || name === 'companyCode') {
          return false
        }

        return true
      })

      return filteredFormData
    }, [customizationItems, isUserRole, user?.restaurants])

    const onSubmit: SubmitHandler<PersonalCardFormValues> = async (createFormData) => {
      const mappedCreateFormData = Object.entries(createFormData).reduce<{ [key: string]: any }>(
        (mainObj, [mainKey, mainValue]) => {
          if (!mainValue) {
            mainObj[mainKey] = null
            return mainObj
          }

          if (mainKey === 'discount') {
            mainObj[mainKey] = Object.entries<any>(mainValue).reduce<{ [key: string]: any }>(
              (discountObj, [discountKey, discountValue]) => {
                if (!discountValue) {
                  discountObj[discountKey] = null
                  return discountObj
                }

                if (discountKey === 'validTo') {
                  discountObj[discountKey] = dayjs.utc(discountValue).unix()
                  return discountObj
                }

                discountObj[discountKey] = Number(discountValue)
                return discountObj
              },
              {},
            )
            return mainObj
          }

          mainObj[mainKey] = mainValue
          return mainObj
        },
        {},
      ) as CreateLoyaltyCardBulkContract

      const res = await onCreate?.(mappedCreateFormData)

      setFormErrors(res?.errors, setError)
    }

    const createCard = () => {
      handleSubmit(onSubmit)()
    }

    useEffect(() => {
      reset({
        ...INITIAL_PERSONAL_CARD_FORM_VALUES,
        discount: {
          ...INITIAL_PERSONAL_CARD_FORM_VALUES.discount,
          validTo: dayjs().endOf('day').add(1, 'month').format(DATE_FORMAT),
        },
        customizationId: user?.customizationId ?? '',
        restaurantIds: isUserRole ? user?.restaurants?.map((restaurant) => restaurant.id) ?? [] : [],
      })
    }, [isUserRole, reset, user?.customizationId, user?.restaurants])

    useImperativeHandle(
      ref,
      () => {
        return { createCard }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [],
    )

    return <Form control={control} errors={errors} formInputsData={personalCardFormData} loading={loading} />
  },
)

export default PersonalCardForm
